.top-bar {
  width: 100%;
  min-height: 54px;
  max-height: 54px;
  border-bottom: 1px solid rgba(218, 220, 224, 0.5);
  background: #fff;
  .dot {
    margin-left: -26px;
    margin-right: 11px;
    margin-top: 28px;
    width: 11px;
    height: 11px;
    border-radius: 8px;
    background: gray;
    border: 2px solid #fff; }
  .online {
    background: #55d48b; }
  .away {
    background: orange; }
  .busy {
    background: #d61314; }
  .offline {
    color: gray; }
  .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-weight: bold;
      font-size: 13px; }
    .message {
      font-size: 11px; } }
  .profile.conversation {
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 7px 12px 7px 5px;
    .img {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      background:  #2d30e1;
      color: #ffffff;
      font-size: 18px; } }
  .nav {
    display: flex;
    padding: 0 9px;
    .button {
      color: #666;
      font-size: 18px;
      padding: 9px;
      cursor: pointer; }
    .button:hover {
      color: #000; }
    .button.active {
      color: #da7d02 !important; } } }

@media screen and (min-width: 700px) {
  .mobile {
    display: none; } }
