$nav-width: 96px;
$main-color: #ff0000;
$nav-background: #303841;
$panel-background: #363e47;

.details-room {
  height: 100%;
  min-width: 280px;
  background: #fcffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .details-button {
    min-height: 40px;
    width: 100%; }

  .profile {
    margin: 40px;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    overflow: hidden;
    flex-shrink: 0; }

  .profile .img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $nav-background;
    color: #ffffff;
    font-size: 80px; }

  .name {
    text-align: center;
    font-size: 24px;
    margin-top: -14px; }

  .title {
    text-align: center;
    font-size: 14px; }

  .notice-text {
    text-align: center;
    font-size: 14px;
    padding-top: 12px;
    color: $panel-background; }

  .images {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    margin: 2px 0 2px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;

    .row {
      display: flex;
      flex-direction: row;
      min-height: 149px;

      img:first-child {
        margin-left: 2px;
        margin-right: 1px; }

      img {
        width: 147px;
        min-height: 147px;
        max-height: 147px;
        height: 147px;
        object-fit: cover;
        margin-left: 1px;
        margin-right: 2px;
        margin-bottom: 2px;
        cursor: pointer; } } }

  .images::-webkit-scrollbar {
    display: none; }

  .notice-text {
    padding: 20px 20px; } }

.members {
  display: flex;
  flex-direction: column;
  background: white;
  color: #666;
  width: 100%;
  height: 100%;
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: scroll;

  .member {
    display: flex;
    align-items: center;
    height: 54px;
    border-bottom: 1px solid rgba(218, 220, 224, 0.5);
    font-size: 14px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin: 7px 14px 7px 16px; }

    .img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $nav-background;
      color: #f7f7f8;
      margin: 7px 14px 7px 16px; }

    .text {
      flex-grow: 1; } }

  .online {
    color: #55d48b; }
  .away {
    color: orange; }
  .busy {
    color: #d61314; }
  .offline {
    color: gray; } }
