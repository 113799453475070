.login {
  width: 100vw;
  background-image: url('/assets/background/640x700.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%; }

.login-scrollable {
  overflow-y: auto;
  height: 100%;
  width: 100%; }

.login-inner {
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.toggle-credits {
  width: 100%; }

@media screen and (max-height: 600px) {
  .login-scrollable {
    align-items: flex-start; } }

@media screen and (max-height: 620px) {
  #copyright {
    display: none; } }

@media screen and (min-width: 640px) {
  .login {
    background-image: url('/assets/background/960x700.png'); } }

@media screen and (min-width: 960px) {
  .login {
    background-image: url('/assets/background/1200x900.png'); } }

@media screen and (min-width: 1200px) {
  .login {
    background-image: url('/assets/background/1600x950.png'); } }

@media screen and (min-width: 1600px) {
  .login {
    background-image: url('/assets/background/2000x1050.png'); } }

.logo {
  padding: 0 95px 10px 95px; }
