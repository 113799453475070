.interface {

  .remote-peer {
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    .name {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #666;
      padding: 5px 0; }

    .img {
      display: flex;
      width: 120px;
      height: 120px;
      border-radius: 75px;
      justify-content: center;
      align-items: center;
      background:  #2d30e1;
      color: #ffffff;
      font-size: 50px; }

    img {
      width: 120px;
      height: 120px;
      border-radius: 75px; }

    .status {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      font-size: 18px;
      font-weight: bold;
      color: #666;
      padding: 5px 0; }

    @media screen and (max-width: 700px) {
      .name {
        font-size: 14px; }
      .img {
        width: 75px;
        height: 75px;
        font-size: 30px; }
      img {
        width: 75px;
        height: 75px; }
      .status {
        font-size: 14px; } } } }


