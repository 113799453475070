body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 

@font-face {
  font-family: 'Champ-Bold';
  src: url('assets//fonticon/Champ-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Champ-Light';
  src: url('assets//fonticon/Champ-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('assets//fonticon/Poppins-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('assets//fonticon/Poppins-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url('assets//fonticon/Poppins-LightItalic.ttf') format('opentype');
}

 
.champ-bold {
  font-family: 'Champ-Bold', sans-serif !important;
}
.champ-light {
  font-family: 'Champ-Light', sans-serif !important;
}

.poppins-light {
  font-family: 'Poppins-Light', sans-serif !important;
}

.poppins-bold {
  font-family: 'Poppins-Bold', sans-serif !important;
}

.poppins-light-italic {
  font-family: 'Poppins-LightItalic', sans-serif !important;
}

.blue-michigan {
  color: #2d30e1;
}

    